import React, { ChangeEvent } from "react";
import { COLORES } from "../constants";

type InputProps = {
  id: string;
  titulo: string;
  valor: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: any,
  tipo?: string;
  obligatorio?: boolean;
};

const InputGenerico: React.FC<InputProps> = ({
  id,
  titulo,
  valor,
  onChange,
  onKeyDown = undefined,
  tipo = "text",
  obligatorio = false,
}) => {
  // Estilos tipados correctamente como React.CSSProperties
  const formulario: React.CSSProperties = {
    position: "relative",
    margin: "40px 20px",
    width: "100%",
  };

  const campo: React.CSSProperties = {
    width: "100%",
    padding: "16px 12px",
    fontSize: "18px",
    border: "1px solid " + COLORES.GRIS_NEUTRO,
    backgroundColor: COLORES.BLANCO,
    borderRadius: "4px",
    outline: "none",
    transition: "border-color 0.3s ease",
    marginTop: "20px",
  };

  const campoFocus: React.CSSProperties = {
    borderColor: COLORES.GRIS_OSCURO,
  };

  const etiqueta: React.CSSProperties = {
    position: "absolute",
    top: "-10px", // Colocar la etiqueta a 10px por encima del borde del campo
    left: "0px",
    fontSize: "20px",
    backgroundColor: COLORES.GRIS_FONDO, // Fondo gris, coincidiendo con el fondo de la página
    padding: "0 5px", // Espaciado para que no recorte la etiqueta
    color: COLORES.NEGRO,
    pointerEvents: "none",
    zIndex: 1, // Asegurar que la etiqueta flote sobre el borde del input
    transition: "all 0.3s ease",
  };

  return (
    <div style={formulario}>
      <input
        type={tipo}
        id={id}
        value={valor}
        onKeyDown={onKeyDown}
        onChange={onChange}
        placeholder=" " // Espacio vacío para activar el efecto flotante
        required={obligatorio}
        style={{
          ...campo,
          ...(valor ? campoFocus : {}), // Aplicamos estilos condicionalmente
        }}
      />
      <label
        htmlFor={id}
        style={etiqueta}
      >
        {titulo}
      </label>
    </div>
  );
};

export default InputGenerico;
