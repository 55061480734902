import React from "react";
import { Link, useLocation } from "react-router-dom";
import { COLORES } from "../constants";

const Header: React.FC = () => {
  const location = useLocation();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "200vw",
        maxWidth: "100%",
        backgroundColor: COLORES.VERDE_TERCIARIO,
      }}
    >
      <div style={{ margin: "5px 20px" }}>
        <img src="./logo1.png" alt="logo Botanic Pasi&oacute;n" height={50} />
      </div>
      <div
        style={{
          width: 400,
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "100%",
          margin: "10px 20px",
          fontSize: 18,
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            padding: "10px 21px",
            borderRadius: 40,
            fontWeight: 600,
            fontFamily: "Roboto, roboto, sans-serif",
            letterSpacing: 2,
            backgroundColor:
              location.pathname === "/" ? COLORES.VERDE_PINCIPAL : undefined,
            color:
              location.pathname === "/" ? COLORES.BLANCO : COLORES.GRIS_OSCURO,
          }}
        >
          Inicio
        </Link>
        <Link
          to="/productos"
          style={{
            textDecoration: "none",
            padding: "10px 21px",
            borderRadius: 40,
            fontWeight: 600,
            fontFamily: "Roboto, roboto, sans-serif",
            letterSpacing: 2,
            backgroundColor:
              location.pathname === "/productos"
                ? COLORES.VERDE_PINCIPAL
                : undefined,
            color:
              location.pathname === "/productos"
                ? COLORES.BLANCO
                : COLORES.GRIS_OSCURO,
          }}
        >
          Productos
        </Link>
        <Link
          to="/contacto"
          style={{
            textDecoration: "none",
            padding: "10px 21px",
            borderRadius: 40,
            fontWeight: 600,
            fontFamily: "Roboto, roboto, sans-serif",
            letterSpacing: 2,
            backgroundColor:
              location.pathname === "/contacto"
                ? COLORES.VERDE_PINCIPAL
                : undefined,
            color:
              location.pathname === "/contacto"
                ? COLORES.BLANCO
                : COLORES.GRIS_OSCURO,
          }}
        >
          Contacto
        </Link>
      </div>
    </div>
  );
};

const styles = {
  navMenu: {},
};

export default Header;
