import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Producto from "../componentes/Producto";
import { CATEGORIAS, COLORES } from "../constants";

const Productos: React.FC = () => {
  const cambiarPagina = useNavigate();
  const [seleccionado, setSeleccionado] = useState('Todo')

  const verDetalle = () => {
    cambiarPagina("/");
  };

  return (
    <div>
      <h1>Productos</h1>
      <p style={{margin: '20px 0px'}}>Esta es la página de productos</p>
      <div>
        <Link 
          to={"#"} 
          style={seleccionado === 'Todo' ? filtroProductosSeleccionado : filtroProductos}
          onClick={() => {setSeleccionado('Todo')}}
        >
          Todo
        </Link>
        <Link 
          to={"#"} 
          style={seleccionado === CATEGORIAS.JARDIN ? filtroProductosSeleccionado : filtroProductos}
          onClick={() => {setSeleccionado(CATEGORIAS.JARDIN)}}
        >
          Jardines
        </Link>
        <Link 
          to={"#"} 
          style={seleccionado === CATEGORIAS.TERRARIO ? filtroProductosSeleccionado : filtroProductos}
          onClick={() => {setSeleccionado(CATEGORIAS.TERRARIO)}}
        >
          Terrarios
        </Link>
      </div>      
      <div style={galeriaProductos}>
        <Producto
          imagen="https://via.placeholder.com/300"
          title="Producto de ejemplo"
          description="Esta es la descripción de un producto genial. ¡No te lo pierdas!"
          onDetailsClick={verDetalle} 
          categoria={CATEGORIAS.TERRARIO}
        />
        <Producto
          imagen="https://via.placeholder.com/300"
          title="Producto de ejemplo"
          description="Esta es la descripción de un producto genial. ¡No te lo pierdas!"
          onDetailsClick={verDetalle} 
          categoria={CATEGORIAS.JARDIN}
        />
        <Producto
          imagen="https://via.placeholder.com/300"
          title="Producto de ejemplo"
          description="Esta es la descripción de un producto genial. ¡No te lo pierdas!"
          onDetailsClick={verDetalle} 
          categoria={CATEGORIAS.JARDIN} 
          oferta={true} 
          precioOferta={10}
        />
        <Producto
          imagen="https://via.placeholder.com/300"
          title="Producto de ejemplo"
          description="Esta es la descripción de un producto genial. ¡No te lo pierdas!"
          onDetailsClick={verDetalle}
        />
      </div>
    </div>
  );
};

const contenedorFiltros = {
  display: 'flex', 
  justifyContent: 'center',
  alingItems: 'center',
}

const filtroProductos: React.CSSProperties = {
  border: '1px solid ' + COLORES.GRIS_NEUTRO,
  borderRadius: 20,
  padding: '5px 10px',
  textDecoration: 'none',
  color: COLORES.GRIS_NEUTRO,
  margin: '0px 10px',
};

const filtroProductosSeleccionado: React.CSSProperties = {
  border: '1px solid ' + COLORES.VERDE_TERCIARIO,
  backgroundColor: COLORES.VERDE_TERCIARIO,
  borderRadius: 20,
  padding: '5px 10px',
  textDecoration: 'none',
  color: COLORES.GRIS_OSCURO,
  margin: '0px 10px',
};

const galeriaProductos: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap", // Corregir el tipo de flexWrap
  gap: "20px", // gap acepta string o number, así que no hay problema aquí
  justifyContent: "center",
};

export default Productos;
