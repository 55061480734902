import React from "react";
import { COLORES } from "../constants";

// Definición del tipo de las props del componente
interface ProductoProps {
  imagen: string;
  title: string;
  description: string;
  categoria?: string;
  oferta?: boolean;
  precioOferta?: number;
  onDetailsClick: () => void;
}

const Producto: React.FC<ProductoProps> = ({
  imagen,
  title,
  description,
  categoria,
  oferta,
  precioOferta,
  onDetailsClick,
}) => {
  return (
    <div style={contenedor}>
      <img src={imagen} alt={title} style={imagenProducto} />
      <div style={contenedorEtiqueta}>
        <span style={etiqueta}>{categoria}</span>
        {oferta && 
          <span style={etiquetaOferta}>Oferta</span>
        }
      </div>
      <h2 style={tituloProducto}>{title}</h2>
      <p style={descipcionProducto}>{description}</p>
      <button style={botonDetallesProducto} onClick={onDetailsClick}>
        Ver detalles
      </button>
    </div>
  );
};

// Estilos en línea (ahora tipados correctamente con React.CSSProperties)
const contenedor: React.CSSProperties = {
  border: "1px solid #ccc",
  borderRadius: "8px",
  margin: '20px',
  padding: "16px",
  textAlign: "center", // Este es el valor correcto de textAlign
  maxWidth: "300px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const imagenProducto: React.CSSProperties = {
  width: "100%",
  height: "auto",
  borderRadius: "8px",
};

const tituloProducto: React.CSSProperties = {
  fontSize: "1.5em",
  margin: "5px 0",
};

const descipcionProducto: React.CSSProperties = {
  fontSize: "1em",
  color: COLORES.GRIS_NEUTRO,
};

const botonDetallesProducto: React.CSSProperties = {
  backgroundColor: COLORES.VERDE,
  color: COLORES.BLANCO,
  padding: "10px 16px",
  border: "none",
  borderRadius: 20,
  cursor: "pointer",
};

const contenedorEtiqueta = {
  marginTop: '10px',
  display: 'flex', 
  alingItems: 'center',
  justifyContent: 'center',
  //justifyContent: 'space-around'
}

const etiqueta = {
  backgroundColor: COLORES.VERDE_TERCIARIO,
  color: COLORES.GRIS_OSCURO,
  padding: '5px 10px',
  margin: '0px 10px',
  borderRadius: 20,
}

const etiquetaOferta = {
  backgroundColor: COLORES.ROJO_ERROR,
  color: COLORES.BLANCO,
  padding: '5px 10px',
  margin: '0px 10px',
  borderRadius: 20,
}

export default Producto;
