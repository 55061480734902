import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Inicio: React.FC = () => {
  const cambiarPagina = useNavigate();
  return (
    <div
      style={{
        width: "100%", // Asegura que ocupe todo el ancho permitido
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center", // Opcional: para centrar el texto
      }}
    >
      <h1>Bienvenido a Botanic pasion</h1>
      <p>Esta es la página de inicio</p>
    </div>
  );
};

export default Inicio;
