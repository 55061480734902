import React from "react";
import { useNavigate } from "react-router-dom";
import { COLORES } from "../constants";

type BotonAdministracionProps = {
  titulo: string; // Texto debajo del ícono
  icono: React.ReactNode; // Ícono en la parte superior
  destino: string; // Ruta a la que navegar al hacer clic
};

const BotonAdministracion: React.FC<BotonAdministracionProps> = ({
  titulo,
  icono,
  destino,
}) => {
  const navigate = useNavigate();

  const contenedor: React.CSSProperties = {
    transform: "scale(1)",
    display: "flex",
    padding: '10px',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "150px",
    height: "150px",
    borderRadius: "12px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Sombra ligera
    backgroundColor: "#fff", // Fondo blanco
    margin: '20px',
    cursor: "pointer",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
  };

  const contenedorHover: React.CSSProperties = {
    transform: "scale(1.05)", // Crece ligeramente al pasar el ratón
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)", // Sombra más intensa al pasar el ratón
  };

  const iconoEstilo: React.CSSProperties = {
    fontSize: "46px",
    marginBottom: "8px",
    color: COLORES.VERDE,
  };

  const tituloEstilo: React.CSSProperties = {
    fontSize: "18px",
    fontWeight: "bold",
    color: COLORES.GRIS_NEUTRO,
    textAlign: "center",
  };

  const handleClick = () => {
    navigate(destino);
  };

  return (
    <div
      style={contenedor}
      onClick={handleClick}
      onMouseEnter={(e) =>
        Object.assign(e.currentTarget.style, contenedorHover)
      }
      onMouseLeave={(e) => Object.assign(e.currentTarget.style, contenedor)}
    >
      <div style={iconoEstilo}>{icono}</div>
      <div style={tituloEstilo}>{titulo}</div>
    </div>
  );
};

export default BotonAdministracion;
