import "./App.css";
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom"; // Usamos HashRouter
import Header from "./componentes/Header";
import Footer from "./componentes/Footer";
import Inicio from "./paginas/Inicio";
import Productos from "./paginas/Productos";
import Contacto from "./paginas/Contacto";
import Administracion from "./paginas/Administracion";
import { COLORES } from "./constants";

function App() {
  return (
    <div
      className="App"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundImage: `url("./fondo.png")`,
        backgroundSize: "100% auto",
        backgroundRepeat: "repeat-y",
        backgroundPosition: "top center",
        width: "100vw",
      }}
    >
      {/* Usamos HashRouter aquí */}
      <Router>
        <Header />
        <div
          style={{
            backgroundColor: COLORES.GRIS_FONDO,
            width: "100%",
            maxWidth: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexGrow: 1,
            margin: "20px auto",
            minHeight: "70%",
            borderRadius: 50,
            alignItems: "center",
            overflow: "auto",
          }}
        >
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/productos" element={<Productos />} />
            <Route path="/administracion" element={<Administracion />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
