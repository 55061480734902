import React, { useRef, useState } from "react"
import InputGenerico from "../componentes/InputGenerico"
import { COLORES } from "../constants"
import BotonAdministracion from "../componentes/BotonAdministracion"
import { PiPlantFill } from "react-icons/pi"
import { MdAddToPhotos, MdDelete } from "react-icons/md";

const Administracion = () => {
    const [usuario, setUsuario] = useState('')
    const [contraseña, setContraseña] = useState('')
    const [administrador, setAdministrador] = useState(false)

    const login = () => {
        if (usuario === 'asartus' && contraseña === 'p4ul4m0l4') {
            setAdministrador(true);
            console.log("Login exitoso");
        } else {
            console.log("Credenciales incorrectas");
        }
    }
    
    const boton = {
        fontSize: 18,
        marginTop: 20,
        backgroundColor: COLORES.VERDE,
        color: COLORES.BLANCO,
        border: 'none',
        padding: '10px 16px',
        borderRadius: 20,
    }

    const contraseñaRef = useRef<HTMLInputElement>(null)

    const enterEnUsuario = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          // Al presionar Enter en usuario, mover el foco al campo contraseña
          contraseñaRef.current?.focus()
        }
    }

    const enterEnContraseña = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
        // Al presionar Enter en contraseña, ejecutar la función login
        login()
    }
    }

    const panelAdministracion = {
        width: '100%',
        display: 'flex', 
        justifyContent: 'space-between'
    }

    return (
        <div>
            {!administrador ? (
                <div>
                    <h1 style={{ margin: "40px 0px" }}>
                        Acceso al panel de administraci&oacute;n
                    </h1>
                    <div style={{ marginTop: "30%" }}>
                        <InputGenerico
                            titulo={"Usuario"}
                            id={"usuario"}
                            valor={usuario}
                            onKeyDown={enterEnUsuario}
                            onChange={(e) => setUsuario(e.target.value)}
                        />
                        <InputGenerico
                            titulo={"Contraseña"}
                            id={"pass"}
                            valor={contraseña}
                            onChange={(e) => setContraseña(e.target.value)}
                            onKeyDown={enterEnContraseña}
                            tipo={'password'}
                        />
                    </div>
                    <button style={boton} onClick={login}>
                        Entrar
                    </button>
                </div>
            ) : (
                <div>
                    <h2>Panel de Administración</h2>
                    <div style={panelAdministracion}>
                        <BotonAdministracion titulo={"Añadir creación"} icono={<PiPlantFill />} destino={"#"} />
                        <BotonAdministracion titulo={"Nuevo tipo de creación"} icono={<MdAddToPhotos />} destino={"#"} />
                        <BotonAdministracion titulo={"Eliminar creación"} icono={<MdDelete />} destino={"#"} />
                    </div>
                </div>
            )}
        </div>
    )    
}

export default Administracion