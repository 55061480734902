import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Contacto: React.FC = () => {
  const cambiarPagina = useNavigate();
  return (
    <div>
      <h1>Contacto</h1>
      <p>Esta es la página de contacto</p>
    </div>
  );
};

export default Contacto;
