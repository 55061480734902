export const COLORES = {
  VERDE: "#498B2A",
  VERDE_PINCIPAL: "#82AD6A",
  VERDE_SECUNDARIO: "#9CBE89",
  VERDE_TERCIARIO: "#B6CFA8",

  BLANCO: "#FFF",
  NEGRO: "#000",
  GRIS_SUAVE: "#CCC",
  GRIS_OSCURO: "#2E2E2E",
  GRIS_NEUTRO: "#666",
  GRIS_FONDO: "#F7F7F7",

  VERDE_EXITO: "#10B981",
  ROJO_ERROR: "#EF4444",
};

export const CATEGORIAS = {
  TERRARIO: 'Terrario',
  JARDIN: 'Jardín',
}
