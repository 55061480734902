import React from "react";
import { COLORES } from "../constants";
import dayjs from "dayjs";

const Footer: React.FC = () => {
  return (
    <div
      style={{
        padding: "10px 0px", // Relleno arriba y abajo
        backgroundColor: COLORES.VERDE_TERCIARIO, // Color de fondo del pie de página
        width: "100%", // Asegura que el footer ocupe todo el ancho de la pantalla
        textAlign: "center", // Centra el texto en el footer
      }}
    >
      <span>Botanic Pasi&oacute;n {dayjs().format("YYYY")} &copy;</span>
    </div>
  );
};

export default Footer;
